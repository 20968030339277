import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Socket, SocketIoConfig } from './../../../node_modules/ngx-socket-io';
import { COOKIE_SUFFIX,ILLUMINAIRE_URL } from './../../../src/app/config/apiRouteConfig';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  config: SocketIoConfig = {
    url: ILLUMINAIRE_URL,
    options: { }
  };

  constructor(private socket: Socket,private _ckService: CookieService) {
   //this.socket = new Socket(this.config);
  }

    setConnection() {
    if(!this.socket.ioSocket.connected){
       let accessToken = this._ckService.get('accessToken'+COOKIE_SUFFIX)
       let ssoId = localStorage.getItem("ssoId")
       this.config.options = { query :{ accessToken : accessToken , ssoId: ssoId }}
       this.socket = new Socket(this.config)
       return this.socket;
    }
    return this.socket;
  }
  disconnectSocket() {
    if (this.socket.ioSocket.connected) 
      this.socket.disconnect();
  }
}